import axios from "@/plugins/Axios";

const model = "verificacion";

function all() {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}`);
}

function find(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function create(verificacion) {
  return axios.post(`${process.env.VUE_APP_API_URL}/${model}`, verificacion);
}

function update(id, verificacion) {
  return axios.put(
    `${process.env.VUE_APP_API_URL}/${model}/${id}`,
    verificacion
  );
}

function remove(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

export { all, find, create, update, remove };
