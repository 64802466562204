<template>
  <form id="newRecord" @submit.prevent="saveOrUpdateMarcoLegalEspecifico">
    <c-422-errors :errors="errors" />
    <!-- Nombre -->
    <div class="mb-3">
      <label for="name" class="form-label">Nombre:</label>
      <input
        v-model="marcoLegalEspecifico.name"
        class="form-control"
        id="name"
        required
      />
    </div>
    <!-- aspecto -->
    <div class="mb-3">
      <label for="aspecto" class="form-label">Aspecto:</label>
      <select
        id="aspecto"
        class="form-select"
        required
        v-model="marcoLegalEspecifico.aspecto_id"
      >
        <option
          v-for="aspecto in aspectos"
          :value="aspecto.id"
          :key="aspecto.id"
        >
          {{ aspecto.name }}
        </option>
      </select>
    </div>
    <!-- Requerimiento -->
    <div class="mb-3">
      <label for="requirement" class="form-label">Requerimiento:</label>
      <textarea
        v-model="marcoLegalEspecifico.requerimiento"
        class="form-control"
        id="requirement"
        rows="3"
        required
      />
    </div>
    <!-- Ordenamiento -->
    <div class="mb-3">
      <label for="ordenamiento" class="form-label">Ordenamiento:</label>
      <select
        id="ordenamiento"
        v-model="marcoLegalEspecifico.ordenamiento_id"
        class="form-select"
        required
      >
        <option
          v-for="ordenamiento in ordenamientos"
          :value="ordenamiento.id"
          :key="ordenamiento.id"
        >
          {{ ordenamiento.name }}
        </option>
      </select>
    </div>
    <!-- ámbito -->
    <div class="mb-3">
      <label for="ambito" class="form-label">Ámbito:</label>
      <select
        id="ambito"
        v-model="marcoLegalEspecifico.ambito_id"
        class="form-select"
        required
      >
        <option v-for="ambito in ambitos" :value="ambito.id" :key="ambito.id">
          {{ ambito.name }}
        </option>
      </select>
    </div>
    <!--  estado  -->
    <div v-if="marcoLegalEspecifico.ambito_id !== ambitoFederalId" class="mb-3">
      <label for="state" class="form-label">Estado:</label>
      <select
        @change="getStateCities"
        id="state"
        class="form-select"
        v-model="marcoLegalEspecifico.state_id"
        required
      >
        <option v-for="state in states" :value="state.id" :key="state.id">
          {{ state.name }}
        </option>
      </select>
    </div>
    <!-- municipio -->
    <div v-if="marcoLegalEspecifico.ambito_id !== ambitoFederalId" class="mb-3">
      <label for="city" class="form-label">Municipio:</label>
      <select
        id="city"
        class="form-select"
        required
        v-model="marcoLegalEspecifico.cities_id"
      >
        <option v-for="city in cities" :value="city.id" :key="city.id">
          {{ city.name }}
        </option>
      </select>
    </div>
    <!-- link oficial -->
    <div class="mb-3">
      <label for="oficialLink" class="form-label">Link oficial:</label>
      <input
        v-model="marcoLegalEspecifico.link_oficial"
        class="form-control"
        id="oficialLink"
        required
      />
    </div>
    <!-- Fecha de publicación y fecha de aplicación-->
    <div class="row">
      <div class="col-md-6">
        <div class="mb-3">
          <label for="oficialPublishDate" class="form-label"
            >Fecha de publicación oficial:</label
          >
          <input
            v-model="marcoLegalEspecifico.fecha_publicacion_oficial"
            type="date"
            class="form-control"
            id="oficialPublishDate"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="applicationDate" class="form-label"
            >Fecha de aplicación:</label
          >
          <input
            v-model="marcoLegalEspecifico.fecha_entrada_en_vigor"
            type="date"
            class="form-control"
            id="applicationDate"
            required
          />
        </div>
      </div>
    </div>
    <!-- palabras clave -->
    <div class="mb-3">
      <label for="records_keywords" class="form-label">Palabras Clave:</label>
      <input
        v-model="marcoLegalEspecifico.palabras_clave"
        class="form-control"
        id="records_keywords"
        required
      />
    </div>
    <!-- autoridad regulatoria y tipo de verificación -->
    <div class="row">
      <div class="col-md-6">
        <div class="mb-3">
          <label for="regulatoryAuthority" class="form-label"
            >Autoridad regulatoria:</label
          >
          <select
            id="regulatoryAuthority"
            class="form-select"
            required
            v-model="marcoLegalEspecifico.autoridad_reguladora_id"
          >
            <option
              v-for="autoridad in autoridades"
              :value="autoridad.id"
              :key="autoridad.id"
            >
              {{ autoridad.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="verificationType" class="form-label"
            >Tipo de verificación:</label
          >
          <select
            id="verificationType"
            v-model="marcoLegalEspecifico.verificacion_id"
            class="form-select"
            required
          >
            <option
              v-for="verificacion in verificaciones"
              :value="verificacion.id"
              :key="verificacion.id"
            >
              {{ verificacion.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <!-- semáforo-->
    <div class="row">
      <div class="col-md-12">
        <div class="mb-3">
          <label for="semaforo" class="form-label">Semaforo:</label>
          <select
            id="semaforo"
            v-model="marcoLegalEspecifico.semaforo_id"
            class="form-select"
            required
          >
            <option
              v-for="semaforo in semaforos"
              :value="semaforo.id"
              :key="semaforo.id"
            >
              {{ semaforo.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <!-- frecuencia y requiere programación de análisis de estudio y/o reporte   -->
    <div class="row justify-content-center align-items-baseline">
      <div class="col-md-6">
        <div class="mb-3">
          <label for="analysisRequired" class="form-label"
            >¿Requiere programación de análisis, estudio y/o reporte?:</label
          >
          <select
            id="analysisRequired"
            v-model="
              marcoLegalEspecifico.requerido_programacion_de_analisis_de_estudio_y_o_reporte
            "
            class="form-select"
          >
            <option :value="'Sí'">Sí</option>
            <option :value="'No'">No</option>
            <option :value="'No aplica'">No Aplica</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="frequency" class="form-label">Frecuencia:</label>
          <select
            id="frequency"
            v-model="marcoLegalEspecifico.frequencies_id"
            class="form-select"
          >
            <option
              v-for="frequency in frequencies"
              :value="frequency.id"
              :key="frequency.id"
            >
              {{ frequency.frecuency }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <c-primary-button class="w-100" :disabled="loading">
      Guardar
    </c-primary-button>
  </form>
</template>
<script>
import C422Errors from "@/components/C422Errors";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import {
  createMarcosLegalesEspecificos,
  marcosLegalesEspecificos,
} from "@/services/marcos_legales_generales_versions";
import { all as allAspectos } from "@/services/aspectos";
import { showNotification } from "@/services/notification";
import { all as allOrdenamientos } from "@/services/ordenamientos";
import { all as allAmbitos } from "@/services/ambitos";
import { all as allStates, cities } from "@/services/states";
import { all as allAutoridades } from "@/services/autoridades";
import { all as allVerificaciones } from "@/services/verificaciones";
import { all as allFrequencies } from "@/services/frequencies";
import { all as allSemaforos } from "@/services/semaforos";
import { find, update } from "@/services/marcos_legales_especificos";

export default {
  components: {
    C422Errors,
    CPrimaryButton,
  },
  data() {
    return {
      marcoLegalGeneralId: null,
      marcoLegalEspecificoId: null,
      marcoLegalGeneralVersionId: null,
      errors: {},
      loading: false,
      aspectos: [],
      ordenamientos: [],
      ambitoFederalId: null,
      ambitos: [],
      states: [],
      cities: [],
      verificaciones: [],
      autoridades: [],
      semaforos: [],
      frequencies: [],
      marcoLegalEspecifico: {
        name: "",
        aspecto_id: "",
        palabras_clave: "",
        requerimiento: "",
        ambito_id: "",
        state_id: "",
        cities_id: "",
        link_oficial: "",
        ordenamiento_id: "",
        fecha_publicacion_oficial: "",
        fecha_entrada_en_vigor: "",
        autoridad_reguladora_id: "",
        verificacion_id: "",
        semaforo_id: "",
        requerido_programacion_de_analisis_de_estudio_y_o_reporte: true,
        frequencies_id: "",
      },
    };
  },
  mounted() {
    this.marcoLegalGeneralId = this.$route.params.id;
    this.marcoLegalGeneralVersionId = this.$route.params.versionId;
    this.marcoLegalEspecificoId = this.$route.params.mleId;
    this.getMarcoLegalEspecifico();
    this.getAspectos();
    this.getOrdenamientos();
    this.getAmbitos();
    this.getStates();
    this.getAutoridades();
    this.getVerificaciones();
    this.getSemaforos();
    this.getFrequencies();
  },
  watch: {
    "$route.params.mleId"(value) {
      this.marcoLegalEspecificoId = value;
      this.getMarcoLegalEspecifico();
      this.$emit("setNextAndPrev");
    },
  },
  methods: {
    getMarcoLegalEspecifico() {
      if (!this.marcoLegalEspecificoId) {
        this.setMarcosLegalEspecificoExample();
        return;
      }
      find(this.marcoLegalEspecificoId).then((response) => {
        this.marcoLegalEspecifico = response.data;
        this.getStateCities();
      });
    },
    saveOrUpdateMarcoLegalEspecifico() {
      this.marcoLegalEspecificoId
        ? this.updateMarcoLegalEspecifico()
        : this.saveMarcoLegalEspecifico();
    },
    saveMarcoLegalEspecifico() {
      if (this.ambitoFederalSelected()) {
        this.marcoLegalEspecifico.cities_id = null;
      }
      createMarcosLegalesEspecificos(
        this.marcoLegalGeneralVersionId,
        this.marcoLegalEspecifico
      )
        .then(() => {
          showNotification(201);
          this.$router.push(
            `/admin/marcos-legales-generales/${this.marcoLegalGeneralId}/versiones/${this.marcoLegalGeneralVersionId}/marcos-legales-especificos`
          );
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    updateMarcoLegalEspecifico() {
      update(this.marcoLegalEspecificoId, this.marcoLegalEspecifico).then(
        () => {
          showNotification(201);
          this.$router.push(
            `/admin/marcos-legales-generales/${this.marcoLegalGeneralId}/versiones/${this.marcoLegalGeneralVersionId}/marcos-legales-especificos`
          );
        }
      );
    },
    getAspectos() {
      allAspectos()
        .then((response) => {
          this.aspectos = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar los aspectos");
        });
    },
    getOrdenamientos() {
      allOrdenamientos()
        .then((response) => {
          this.ordenamientos = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar los ordenamientos");
        });
    },
    getAmbitos() {
      allAmbitos()
        .then((response) => {
          this.ambitos = response.data;
          this.ambitoFederalId = this.ambitos.find(
            (ambito) => ambito.name === "Federal"
          ).id;
        })
        .catch(() => {
          alert("No pudimos cargar los ambitos");
        });
    },
    getStates() {
      allStates()
        .then((response) => {
          this.states = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar los estados");
        });
    },
    getStateCities() {
      if (!this.marcoLegalEspecifico.state_id) {
        return;
      }
      cities(this.marcoLegalEspecifico.state_id).then(
        (response) => (this.cities = response.data)
      );
    },
    getAutoridades() {
      allAutoridades()
        .then((response) => {
          this.autoridades = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar las autoridades");
        });
    },
    getVerificaciones() {
      allVerificaciones()
        .then((response) => {
          this.verificaciones = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar las verificaciones");
        });
    },
    getSemaforos() {
      allSemaforos()
        .then((response) => {
          this.semaforos = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar los semaforos");
        });
    },
    getFrequencies() {
      allFrequencies()
        .then((response) => {
          this.frequencies = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar las frecuencias");
        });
    },
    ambitoFederalSelected() {
      return this.marcoLegalEspecifico.ambito_id === this.ambitoFederalId;
    },
    setMarcosLegalEspecificoExample() {
      marcosLegalesEspecificos(this.marcoLegalGeneralVersionId).then(
        (response) => {
          if (response.data.length) {
            this.marcoLegalEspecifico = response.data[0];
            this.getStateCities();
          }
        }
      );
    },
  },
};
</script>
