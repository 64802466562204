import axios from "@/plugins/Axios";

const model = "semaforo";

function all() {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}`);
}

function find(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function create(semaforo) {
  return axios.post(`${process.env.VUE_APP_API_URL}/${model}`, semaforo);
}

function update(id, semaforo) {
  return axios.put(`${process.env.VUE_APP_API_URL}/${model}/${id}`, semaforo);
}

function remove(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

export { all, find, create, update, remove };
